
import { getSdsxxList } from '../../api/declare';
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
interface detail {
  pageNum: number;
  pageSize: number;
  sfzjhm: string,
  isValorem: number,
  state: number,
}
export default class DeclareManage extends Vue {
  @Provide() columns: any =[
    {
      title: '服务方ID',
      dataIndex: 'fwfuuid',
    },
    {
      title: '姓名',
      dataIndex: 'xm',
      slots: { customRender: 'xm' },
    },
    {
      title: '身份证号',
      dataIndex: 'sfzjhm',
    },
    {
      title: '联系电话',
      dataIndex: 'lxdh',
    },
    {
      title: '月收入(元)',
      dataIndex: 'ysr',
    },
    {
      title: '应税收入(元)',
      dataIndex: 'yssr',
    },
    {
      title: '应税所得率',
      dataIndex: 'yssdl',
    },
    {
      title: '计税依据(元)',
      dataIndex: 'jsyj',
    },
    {
      title: '税率',
      dataIndex: 'sl',
    },
    {
      title: '速算扣除数(元)',
      dataIndex: 'sskcs',
    },
    {
      title: '应纳税额(元)',
      dataIndex: 'ynse',
    },
    {
      title: '累计已缴税额(元)',
      dataIndex: 'ljyjse',
    },
    {
      title: '本期应补退税额(元)',
      dataIndex: 'bqybtse',
    },
  ]

  expandedRowKeys:any = []
  //列表数据
  data: any = [];
  // 当前索引
  currentIdx:number = 0
  // 表单数据
  form: {
    sfzjhm: string;
    state: string,
  } = {
    sfzjhm: '',
    state: '0',
  }
  // 下拉框
  handleChange(value: string) {
    this.form.state = value
  };
  // 展开行
  handleExpand(record:any, idx: number):void {
    this.currentIdx = idx
		if (this.expandedRowKeys.length > 0) { 
			//判断当前点击行是否已展开，若展开则把当前key从expandedRowKeys中移除，代表关闭当前展开列
			let index = this.expandedRowKeys.indexOf(this.currentIdx);
			if (index > -1) {
				this.expandedRowKeys.splice(index, 1);
			} else {
				//关闭其他展开行，展开当前点击行
				this.expandedRowKeys = [];
				this.expandedRowKeys.push(this.currentIdx);
			}
		} else {
			//如果当前没有展开列，把当前行绑定的唯一key值放到expandedRowKeys数组中
			this.expandedRowKeys.push(this.currentIdx);
		}
	}
 /**
 * detailQuery类，代扣代缴申报查询
 * @constructor
 * @return {*}
 */
 detailQuery(): void{
   const params: any= {
      pageNum: this.data.pageNum,
      pageSize: 10,
      ...this.form
   }
  this.detailData1(params)
 }

   detailData1(params?: object): void {
     const datAll: any = this.$route.query.id;
     const date = datAll.split(',');
     const data: object = {
      ...params,
      sl: date[2],
      state:this.form.state,
      skssqq:date[0],
      skssqz:date[1],
    }
    getSdsxxList(data).then((res: any) => {
      this.data = res.data
    })
  }
 /**
 * detailData类，代扣代缴明细列表接口
 * declareList接口参数限制
 * @constructor
 * @return {*}
 */
  detailData(params?: object): void {
     const datAll: any = this.$route.query.id;
     const date = datAll.split(',');
     if(date[3]==3){
       this.form.state='3'
     }
     const data: object = {
      ...params,
      sl: date[2],
      state:this.form.state,
      skssqq:date[0],
      skssqz:date[1],
    }
    getSdsxxList(data).then((res: any) => {
      this.data = res.data
    })
  }
  onChange1(pageNumber: number) :void {
    this.data.pageNum = pageNumber
    this.detailQuery()
  }
  mounted(){
    this.detailData(this.form)
   
  }
}
